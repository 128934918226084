import bootstrap from "./bootstrap.module.css"
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faPaperPlane } from '@fortawesome/free-solid-svg-icons'



export default class Newsletter extends React.Component {

	constructor(props) {
		super(props);

		this.state = { 
			loading: false,
			message: '',
			status: '',
		};

        this.doSave = this.doSave.bind(this);
	}

	doSave(e) {

		e.preventDefault();
		const data = new FormData(e.target)

		this.setState({loading: true})
		fetch(`https://cms.fintek.pl/api/newsletter.json`, { method: 'POST', body: data })
		.then(response => response.json())
		.then(resultData => {
			this.setState({ loading: false, status: resultData.status, message: resultData.message })
		})

	}


	render() {
		
		const { loading, status, message } = this.state
		
		return (

			<div className={[`newsletter-box`].join(' ')}>

				<form onSubmit={this.doSave} className={[`bg-newsletter`,bootstrap.mb5,bootstrap.p4,bootstrap.textWhite].join(' ')}>
					<div className={bootstrap.row}>
						<div className={bootstrap.colLg8}>
							<h2>Sfintechowany newsletter!</h2>
							{(message && <p>{message}</p>)}
							{( status !== 'OK' && <>
							{(!message && <p className={bootstrap.mb4}>Zapisz się na nasz Newlsetter. Wiadomości, najlepsze wywiady, raporty branżowe, informacje o eventach, konferencjach i ciekawostkach z rynku</p>)}
							<div className={[bootstrap.dLgFlex,'gap3',bootstrap.alignItemsEnd].join(' ')}>
								<div className={bootstrap.mb3}>
									<label htmlFor="name" className={bootstrap.textUppercase}>Imię</label>
									<input type="text" id="name" name="name" className={bootstrap.formControl+` `+bootstrap.px3+` form-control form-control-dark font-serif-light`} required />
								</div>
								<div className={bootstrap.mb3}>
									<label htmlFor="email" className={bootstrap.textUppercase}>Adres e-mail</label>
									<input type="email" id="email" name="email" className={bootstrap.formControl+` `+bootstrap.px3+` form-control form-control-dark font-serif-light`} required />
								</div>
								<div className={bootstrap.mb3}>
									<button className={bootstrap.btn + ' ' + bootstrap.px4 + ` btn-white ` + bootstrap.btnBlock + (loading ? ' newsletter-component-btn-loading' : '')}>Zapisz się</button>
								</div>						
							</div>
							</>
							)}
						</div>
					</div>
				</form>

			</div>

		)
	}
	
}
